import {defer, type LoaderArgs} from '@shopify/remix-oxygen';
import {Suspense} from 'react';
import {Await, useLoaderData} from '@remix-run/react';
import type {
  CollectionConnection,
  ProductConnection,
} from '@shopify/hydrogen/storefront-api-types';
import {AnalyticsPageType, Image} from '@shopify/hydrogen';

import {ProductSwimlane, FeaturedCollections, Hero} from '~/components';
import {MEDIA_FRAGMENT, PRODUCT_CARD_FRAGMENT} from '~/data/fragments';
import {getHeroPlaceholder} from '~/lib/placeholders';
import {seoPayload} from '~/lib/seo.server';
import {routeHeaders, CACHE_SHORT} from '~/data/cache';
import {type CollectionHero} from '~/components/Hero';
import {COLOR, ROUTE} from '~/constants';
import {
  getFeedbacks,
  getMetaObjectsForPage,
  META_OBJECTS_KEYS,
} from '~/queries/MetaObjects';
import AnnaSignature from '~/components/new/Icons/AnnaSignature';
import ArrowLeftLongBlogIcon from '~/components/new/Icons/ArrowLeftLongBlogIcon';
import {Button} from '@material-tailwind/react';
import FeedbackCarousel from '~/components/new/FeedbackCarousel/FeedbackCarousel';

interface HomeSeoData {
  shop: {
    name: string;
    description: string;
  };
}

export const headers = routeHeaders;

export async function loader({params, context}: LoaderArgs) {
  const {language, country} = context.storefront.i18n;

  if (
    params.locale &&
    params.locale.toLowerCase() !== `${language}-${country}`.toLowerCase()
  ) {
    // If the locale URL param is defined, yet we still are on `EN-US`
    // the the locale param must be invalid, send to the 404 page
    throw new Response(null, {status: 404});
  }

  const {shop, hero} = await context.storefront.query<{
    hero: CollectionHero;
    shop: HomeSeoData;
  }>(HOMEPAGE_SEO_QUERY, {
    variables: {handle: 'freestyle'},
  });

  const metaObjects = await getMetaObjectsForPage(
    META_OBJECTS_KEYS.HOME_PAGE_IMAGES,
    context.storefront,
  );
  const feedbacks = await getFeedbacks(context.storefront);
  const seo = seoPayload.home();

  return defer(
    {
      shop,
      primaryHero: hero,
      feedbacks,
      metaObjects,
      // These different queries are separated to illustrate how 3rd party content
      // fetching can be optimized for both above and below the fold.
      analytics: {
        pageType: AnalyticsPageType.home,
      },
      seo,
    },
    {
      headers: {
        'Cache-Control': CACHE_SHORT,
      },
    },
  );
}

export default function Homepage() {
  const {metaObjects, feedbacks} = useLoaderData<typeof loader>();

  // const {
  //   primaryHero,
  //   secondaryHero,
  //   tertiaryHero,
  //   featuredCollections,
  //   featuredProducts,
  // } = useLoaderData<typeof loader>();

  // TODO: skeletons vs placeholders
  const skeletons = getHeroPlaceholder([{}, {}, {}]);

  return (
    <>
      <div className={'flex flex-col'} style={{backgroundColor: COLOR.WHITE}}>
        {/*section #1*/}
        <div
          className={`
            flex
            justify-end
            w-full
            xs-only:mt-[30px]
            sm-only:mt-[30px]
            mobile:px-[16px]
            old:px-[16px]
            flex-nowrap
            xl:flex-row
            l-only:flex-row
            md-only:flex-row
            sm-only:flex-col-reverse
            xs-only:flex-col-reverse
        `}
          style={{backgroundColor: COLOR.WHITE}}
        >
          <div
            className={`
            flex
            xs-only:w-full
            sm-only:w-full
            xl:w-[50vw]
            l-only:w-[50vw]
            md:w-[50vw]
            desktop:pt-[198px] desktop:pr-[61px] desktop:pl-[67px]
            laptop:pt-[198px] laptop:pr-[61px] laptop:pl-[67px]
            tablet:pt-[198px] tablet:pr-[30px] tablet:pl-[32px]
            mobile:pb-[64px]
            old:pb-[64px]
            left-auto
            flex-col
          `}
            style={{backgroundColor: COLOR.WHITE}}
          >
            <div
              className={
                'text-start text-violet desktop:text-64 laptop:text-64 tablet:text-48 mobile:text-28 old:text-28 font-playfair'
              }
              color={`${COLOR.VIOLET}!important`}
            >
              Le donne sono come i fiori, tutte diverse,
            </div>
            <div className={'flex flex-nowrap flex-col text-end relative'}>
              <span
                className={
                  'whitespace-nowrap text-purple desktop:text-64 laptop:text-64 tablet:text-48 mobile:text-28 old:text-28 h-fit font-playfair italic'
                }
              >
                ma ognuna bella
              </span>
              <span
                className={
                  'text-purple flex-1 desktop:text-64 laptop:text-64 tablet:text-48 mobile:text-28 old:text-28 h-fit font-playfair italic'
                }
              >
                a modo suo
              </span>

              <div
                className={
                  'flex mobile:mt-[8px] old:mt-[8px] desktop:absolute laptop: absolute tablet:absolute mobile:relative old:relative bottom-[10px] max-w-[260px] text-start'
                }
              >
                <span className={'text-violet uppercase text-12 font-graphik'}>
                  L’intimo disegnato e creato completamente a mano in Italia
                </span>
              </div>
            </div>
            <a href={ROUTE.CATALOG} className={`
              desktop:w-[52%] laptop:w-[52%] tablet:w-[52%] mobile:w-[61%] old:w-[61%]
              desktop:h-[70px] laptop:h-[70px] tablet:h-[70px] mobile:h-[45px] old:h-[45px]
              desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[40px] mobile:mt-[16px] old:mt-[16px]
              desktop:self-end laptop:self-end
            `}>
              <Button
                className={`
                btn
                rounded-[3px]
                uppercase
                shadow-none
                hover:shadow-none
                desktop:text-16 laptop:text-16 tablet:text-16 mobile:text-12
                font-graphik
                w-full
                h-full
              `}
                style={{
                  backgroundColor: COLOR.VIOLET,
                  color: COLOR.WHITE,
                }}
              >
                Catalogo
              </Button>
            </a>
          </div>

          <div
            className={`
            flex
            desktop:w-[50vw] laptop:w-[50vw] tablet:w-[50vw] mobile:w-[100%] old:w-[100%]
            desktop:h-[800px] laptop:h-[800px] tablet:h-[800px] mobile:h-[241px] old:h-[241px]
            bg-no-repeat
            bg-cover
            bg-center
        `}
            style={{
              backgroundImage: `url("${metaObjects.couple.src}")`,
            }}
          >
            {/*<Image data={metaObjects.couple} />*/}
          </div>
        </div>

        {/*section #2 */}

        <div
          className={`
          grid
          desktop:grid-cols-72 laptop:grid-cols-72 tablet:grid-cols-29 mobile:grid-cols-29 old:grid-cols-29
          // grid-rows-32
          tablet: grid-rows-42 mobile: grid-rows-42 old: grid-rows-42
          grid-rows-[auto]
          bg-violet
          w-full
          text-white
          desktop:pl-[100px] laptop: pl-[100px] tablet: pl-[80px] mobile:pl-[15px] old:pl-[15px]
          desktop:pt-[80px] laptop: pt-[80px] tablet: pt-[40px] mobile:pt-[52px] old:pt-[52px]
          desktop:pr-[8px] laptop: pr-[8px] tablet: pr-[8px] mobile:pr-[15px] old:pr-[15px]
          desktop:pb-[100px] laptop: pb-[100px] tablet: pb-[40px] mobile:pb-[52px] old:pb-[52px]
        `}
        >
          <div
            className={`
            desktop:col-[1/14] laptop:col-[1/14] tablet:col-[23/29] mobile:col-[23/29] old:col-[23/29]
            desktop:row-[1/13] laptop:row-[1/13] tablet: row-[17/27] mobile: row-[17/27] old: row-[17/27]
            `}
          >
            <Image
              data={metaObjects.bra_red}
              alt=""
              className={
                'h-full tablet:aspect-[72/100] mobile:aspect-[72/100] old:aspect-[72/100]'
              }
            />
          </div>
          <div
            className={`
            desktop:col-[7/18] laptop:col-[7/18] tablet:col-[16/22] mobile:col-[16/22] old:col-[16/22]
            desktop:row-[14/23] laptop:row-[14/23] tablet: row-[17/27] mobile: row-[17/27] old: row-[17/27]
          `}
          >
            <Image
              data={metaObjects.flowers_and_lace}
              alt=""
              className={
                'h-full tablet:aspect-[72/100] mobile:aspect-[72/100] old:aspect-[72/100]'
              }
            />
          </div>

          <div
            className={`
            desktop: col-[24/44] laptop:col-[24/44] tablet:col-[1/14] mobile:col-[1/14] old:col-[1/14]
            desktop: row-[3/6] laptop: row-[3/6] tablet: row-[1/2] mobile: row-[1/2] old: row-[1/2]
            tablet:pb-[15px] mobile:pb-[15px] old:pb-[15px]
          `}
          >
            <span
              className={
                'font-playfair desktop:text-64 laptop:text-64 tablet:text-48 mobile:text-28 old:text-28 whitespace-nowrap'
              }
            >
              <span>La mia</span> <span className={'italic'}>filosofia</span>
            </span>
          </div>

          <div
            className={`
            desktop: col-[24/53] laptop: col-[24/53] tablet:col-[1/72] mobile:col-[1/72] old:col-[1/72]
            desktop:row-[7/12] laptop:row-[7/12] tablet:row-[30/38] mobile:row-[30/38] old:row-[30/38]
            tablet:pt-[25px] mobile:pt-[25px] old:pt-[25px]
          `}
          >
            <span
              className={
                'font-graphik desktop:text-18 laptop: text-18 tablet: text-16 mobile:text-16 old:text-16 '
              }
            >
              <span>
                Ogni donna merita, al di là della sua fisicità, di sentirsi
                bellissima, di essere a proprio agio con il corpo, l’età e le
                curve che la contraddistinguono,
              </span>
              <span className={'font-bold'}>
                {' '}
                e creando la lingerie desidero trasmettere questo importante
                messaggio{' '}
              </span>
            </span>
          </div>

          <div
            className={`
            desktop:col-[24/44] laptop:col-[24/44] tablet:col-[1/15] mobile:col-[1/15] old:col-[1/15]
            desktop:row-[15/34] laptop:row-[15/34] tablet:row-[6/27] mobile:row-[6/27] old:row-[6/27]
            desktop: aspect-[82/100] laptop: aspect-[82/100]tablet: aspect-[74/100] mobile: aspect-[74/100] old: aspect-[74/100]
          `}
          >
            <Image data={metaObjects.curly} />
          </div>

          <div
            className={`
            desktop:col-[57/63] laptop:col-[57/63] tablet:col-[13/22] mobile:col-[13/22] old:col-[13/22]
            desktop:row-[12/14] laptop:row-[12/14] tablet:row-[43/45] mobile:row-[43/45] old: row-[43/45]
          `}
          >
            <AnnaSignature />
          </div>

          <div
            className={`
            desktop:col-[61/69] laptop:col-[61/69] tablet:col-[16/25] mobile:col-[16/25] old:col-[16/25]
            desktop:row-[14/15] laptop:row-[14/15] tablet:row-[46] mobile:row-[46] old:row-[46]
            desktop:pt-[15px] laptop:pt-[15px] tablet:pt-[10px] mobile:pt-[10px] old:pt-[10px]
          `}
          >
            <span className={'uppercase text-12 whitespace-nowrap'}>
              CREATRICE DEL BRAND
            </span>
          </div>

          <div
            className={`
            desktop:col-[50/61] laptop:col-[50/61] tablet:col-[16/22] mobile:col-[16/22] old:col-[16/22]
            desktop:row-[18/27] laptop:row-[18/27] tablet:row-[6/16] mobile:row-[6/16] old:row-[6/16]
          `}
          >
            <Image
              className={`
              h-full
              desktop:aspect-[90/100] laptop:aspect-[90/100] tablet:aspect-[72/100] mobile:aspect-[72/100] old:aspect-[72/100]
            `}
              data={metaObjects.right_body_side}
            />
          </div>
          <div
            className={`
            desktop:col-[62/73] laptop:col-[62/73] tablet:col-[23/29] mobile:col-[23/29] old:col-[23/29]
            desktop:row-[18/27] laptop:row-[18/27] tablet:row-[6/16] mobile:row-[6/16] old:row-[6/16]
          `}
          >
            <Image
              className={
                'h-full desktop: aspect-[90/100] laptop:aspect-[90/100] tablet: aspect-[72/100] mobile: aspect-[72/100] old: aspect-[72/100]'
              }
              data={metaObjects.left_body_side}
              alt=""
            />
          </div>

          <div
            className={`
            flex
            flex-row
            gap-[10px]
            items-center
            flex-nowrap
            desktop:col-[50/60] laptop:col-[50/60] tablet:col-[1/12] mobile:col-[1/12] old:col-[1/12]
            desktop:row-[27] laptop:row-[27] tablet:row-[41] mobile:row-[41] old:row-[41]
            desktop:pt-[30px] laptop:pt-[30px] tablet:pt-[15px] mobile:pt-[15px] old:pt-[15px]
          `}
          >
            <a href={ROUTE.ABOUT}
              className={
                'uppercase font-graphik desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-12 old:text-12 whitespace-nowrap'
              }
            >
              Leggere di piu
            </a>
            <ArrowLeftLongBlogIcon
              className={'rotate-[180deg]'}
              color={COLOR.WHITE}
            />
          </div>
        </div>

        {/*section #3*/}
        <div className={'w-full flex flex-row flex-nowrap'}>
          <div
            className={`
            desktop:w-2/4 laptop:w-2/4 tablet:w-2/4 mobile:w-full old:w-full
            desktop:text-center laptop:text-center tablet:text-center mobile:text-start old:text-start
            desktop:pl-[81px] laptop:pl-[81px] tablet:pl-[62px] mobile:pl-[15px] old:pl-[15px]
            desktop:pr-[59px] laptop:pr-[59px] tablet:pr-[48px] mobile:pr-[15px] old:pr-[15px]
            desktop:py-[77px] laptop:py-[77px] tablet:py-[58px] mobile:py-[51px] old:py-[51px]
            flex
            flex-col
            desktop:gap-[50px] laptop:gap-[50px] tablet:gap-[30px] mobile:gap-[20px] old:gap-[20px]
            desktop:border-b-[1px] laptop:border-b-[1px] tablet:border-b-[1px] border-purpleDark
          `}
          >
            <div
              className={`
              grid
              desktop:grid-cols-6 laptop:grid-cols-6 tablet:grid-cols-12 mobile:grid-cols-12 old:grid-cols-12
              grid-rows-2
            `}
            >
              <div
                className={`
                font-playfair
                row-[1]
                desktop:col-[2/4] laptop:col-[2/4] tablet:col-[1/2] mobile:col-[1/2] old:col-[1/2]
                text-violet
                desktop:text-64 laptop:text-64 tablet:text-48 mobile:text-28 old:text-28
                desktop:leading-[60px] laptop:leading-[60px] tablet:leading-[60px] mobile:leading-[35px] old:leading-[35px]
              `}
              >
                Intimo
              </div>

              <div
                className={`
                font-playfair
                row-[2]
                desktop:col-[3/6] laptop:col-[3/6] tablet:col-[2/4] mobile:col-[2/4] old:col-[2/4]
                text-purpleDark
                italic
                desktop:text-64 laptop:text-64 tablet:text-48 mobile:text-28 old:text-28
                whitespace-nowrap
                desktop:leading-[60px] laptop:leading-[60px] tablet:leading-[60px] mobile:leading-[35px] old:leading-[35px]
              `}
              >
                su misura
              </div>
            </div>

            <div className={'flex justify-center'}>
              <Image
              className={`
                object-cover
                desktop:!w-[35%] laptop:!w-[35%] tablet:!w-[35%] mobile:!w-2/4 old:!w-2/4
                desktop:aspect-[89/100] laptop:aspect-[89/100] tablet:aspect-[89/100] mobile:aspect-[74/100] old:aspect-[74/100]
              `}
                data={metaObjects.measuring}
              />
              <div
                className={`
                items-end
                desktop:hidden laptop:hidden tablet:hidden mobile:flex old:flex
                bg-purpleDark
                w-2/4
                pt-[30px] pb-[10px] px-[20px]
                `}
              >
                <Image
                  className={'h-fit my-auto aspect-[76/100]'}
                  data={metaObjects.curly_flower}
                />
              </div>
            </div>

            <span
              className={
                'font-graphik text-violet desktop:text-18 laptop:text-18 tablet: text-16 mobile:text-16 old:text-16'
              }
            >
              Il mio personale servizio di sartoria su misura, anche a distanza,
              assicura non solo una vestibilità perfetta, ma anche l’opportunità
              di acquistare un capo di qualità, unico, originale, in grado di
              celebrare l’irripetibilità di ogni corpo e offrire comfort ed
              eleganza.
            </span>

            <a href={ROUTE.ABOUT}>
              <Button
                className={`
                  shadow-none
                  hover:shadow-none
                  whitespace-nowrap
                  shadow-none
                  desktop:w-2/4 laptop:w-2/4 tablet:w-3/4 mobile:w-2/4 old:w-2/4
                  desktop:h-[70px] laptop:h-[70px] tablet:h-[60px] mobile:h-[45px] old:h-[45px]
                  desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-12 old:text-12
                  font-graphik uppercase bg-violet rounded-[3px] desktop:self-center laptop:self-center tablet:self-center mobile:self-start old:self-start
              `}
              >
                Leggere di piu
              </Button>
            </a>
          </div>
          <div
            className={`
            desktop:flex laptop:flex tablet:flex mobile:hidden old:hidden
            w-2/4
            bg-purpleDark
            desktop:py-[131px] laptop:py-[131px] tablet: py-[100px]
            desktop:px-[139px] laptop:px-[139px] tablet: px-[102px]
          `}
          >
            <Image
              className={'h-fit my-auto aspect-[76/100]'}
              data={metaObjects.curly_flower}
            />
          </div>
        </div>

        {/*section 4*/}
        <div
          className={`
          w-full
          desktop:py-[100px] laptop:py-[100px] tablet:py-[80px] mobile:py-[60px] old:py-[60px]
          desktop:pl-[100px] laptop:pl-[100px] tablet:pl-[80px] mobile:pl-[15px] old:pl-[15px]
        `}
        >
          <FeedbackCarousel feedbacks={feedbacks} />
        </div>
      </div>

      {/*{primaryHero && (*/}
      {/*  <Hero {...primaryHero} height="full" top loading="eager" />*/}
      {/*)}*/}

      {/*{featuredProducts && (*/}
      {/*  <Suspense>*/}
      {/*    <Await resolve={featuredProducts}>*/}
      {/*      {({products}) => {*/}
      {/*        if (!products?.nodes) return <></>;*/}
      {/*        return (*/}
      {/*          <ProductSwimlane*/}
      {/*            products={products.nodes}*/}
      {/*            title="Featured Products"*/}
      {/*            count={4}*/}
      {/*          />*/}
      {/*        );*/}
      {/*      }}*/}
      {/*    </Await>*/}
      {/*  </Suspense>*/}
      {/*)}*/}

      {/*{secondaryHero && (*/}
      {/*  <Suspense fallback={<Hero {...skeletons[1]} />}>*/}
      {/*    <Await resolve={secondaryHero}>*/}
      {/*      {({hero}) => {*/}
      {/*        if (!hero) return <></>;*/}
      {/*        return <Hero {...hero} />;*/}
      {/*      }}*/}
      {/*    </Await>*/}
      {/*  </Suspense>*/}
      {/*)}*/}

      {/*{featuredCollections && (*/}
      {/*  <Suspense>*/}
      {/*    <Await resolve={featuredCollections}>*/}
      {/*      {({collections}) => {*/}
      {/*        if (!collections?.nodes) return <></>;*/}
      {/*        return (*/}
      {/*          <FeaturedCollections*/}
      {/*            collections={collections.nodes}*/}
      {/*            title="Collections"*/}
      {/*          />*/}
      {/*        );*/}
      {/*      }}*/}
      {/*    </Await>*/}
      {/*  </Suspense>*/}
      {/*)}*/}

      {/*{tertiaryHero && (*/}
      {/*  <Suspense fallback={<Hero {...skeletons[2]} />}>*/}
      {/*    <Await resolve={tertiaryHero}>*/}
      {/*      {({hero}) => {*/}
      {/*        if (!hero) return <></>;*/}
      {/*        return <Hero {...hero} />;*/}
      {/*      }}*/}
      {/*    </Await>*/}
      {/*  </Suspense>*/}
      {/*)}*/}
    </>
  );
}

const COLLECTION_CONTENT_FRAGMENT = `#graphql
  fragment CollectionContent on Collection {
    id
    handle
    title
    descriptionHtml
    heading: metafield(namespace: "hero", key: "title") {
      value
    }
    byline: metafield(namespace: "hero", key: "byline") {
      value
    }
    cta: metafield(namespace: "hero", key: "cta") {
      value
    }
    spread: metafield(namespace: "hero", key: "spread") {
      reference {
        ...Media
      }
    }
    spreadSecondary: metafield(namespace: "hero", key: "spread_secondary") {
      reference {
        ...Media
      }
    }
  }
  ${MEDIA_FRAGMENT}
`;

const HOMEPAGE_SEO_QUERY = `#graphql
  query seoCollectionContent($handle: String, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    hero: collection(handle: $handle) {
      ...CollectionContent
    }
    shop {
      name
      description
    }
  }
  ${COLLECTION_CONTENT_FRAGMENT}
`;

const COLLECTION_HERO_QUERY = `#graphql
  query heroCollectionContent($handle: String, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    hero: collection(handle: $handle) {
      ...CollectionContent
    }
  }
  ${COLLECTION_CONTENT_FRAGMENT}
`;

// @see: https://shopify.dev/api/storefront/2023-04/queries/products
export const HOMEPAGE_FEATURED_PRODUCTS_QUERY = `#graphql
  query homepageFeaturedProducts($country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    products(first: 8) {
      nodes {
        ...ProductCard
      }
    }
  }
  ${PRODUCT_CARD_FRAGMENT}
`;

// @see: https://shopify.dev/api/storefront/2023-04/queries/collections
export const FEATURED_COLLECTIONS_QUERY = `#graphql
  query homepageFeaturedCollections($country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    collections(
      first: 4,
      sortKey: UPDATED_AT
    ) {
      nodes {
        id
        title
        handle
        image {
          altText
          width
          height
          url
        }
      }
    }
  }
`;
