import ArrowLeftLongBlogIcon from '~/components/new/Icons/ArrowLeftLongBlogIcon';
import {useRef} from 'react';

const FeedbackCard = ({text, color}: {text: string; color: string}) => {
  return (
    <div
      className={`
      flex flex-col
      desktop:px-[46px] laptop:px-[46px] tablet:px-[42px] mobile:px-[20px] old:px-[20px]
      desktop:py-[108px] laptop:py-[108px] tablet:py-[92px] mobile:py-[30px] old:py-[30px]
      mobile:pb-[25px] old:pb-[25px]
      desktop:w-[370px] laptop:w-[370px] tablet:w-[340px] mobile:w-[190px] old:w-[190px]
      desktop:h-[485px] laptop:h-[485px] tablet:h-[465px] mobile:h-[255px] old:h-[255px]
      ${color}
    `}
    >
      <div
        className={`
        relative
        text-center
        mobile:text-start old:text-start
        font-graphik
        desktop:text-18 laptop:text-18 tablet:text-14 mobile:text-12 old:text-12
        text-white
        desktop:pt-[30px] laptop:pt-[30px] tablet:pt-[28px] mobile:pt-[20px] old:pt-[20px]
        before:absolute
        before:top-0
        before:h-[1px] before:
        before:bg-white
        before:w-[105px]
        desktop:before:left-[33%] laptop:before:left-[33%] tablet:before:left-[33%] mobile:before:left-0 old:before:left-0
        before:left-0
      `}
      >
        {text}
      </div>
    </div>
  );
};

const FeedbackCarousel = ({feedbacks}: {feedbacks: string[]}) => {
  const scrollTapeRef = useRef<HTMLDivElement>(null);

  const handleNext = () => {
    if (scrollTapeRef.current) {
      const child = scrollTapeRef.current?.children[0];
      const card = child?.children[0];

      const a = scrollTapeRef.current?.scrollLeft;
      if (card) {
        scrollTapeRef.current?.scrollTo({
          left: a + card.clientWidth,
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  };

  const handlePrev = () => {
    if (scrollTapeRef.current) {
      const child = scrollTapeRef.current?.children[0];
      const card = child?.children[0];

      const a = scrollTapeRef.current?.scrollLeft;
      if (card) {
        scrollTapeRef.current?.scrollTo({
          left: a - card.clientWidth,
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  };
  return (
    <div
      className={
        'flex flex-col desktop:gap-[51px] laptop:gap-[51px] tablet:gap-[38px] mobile:gap-[20px] old:gap-[20px]'
      }
    >
      <div
        className={'flex flex-row flex-nowrap justify-between items-center '}
      >
        <span
          className={
            'font-playfair desktop:text-64 laptop:text-64 tablet:text-48 mobile:text-28 old:text-28 text-violet'
          }
        >
          Feedback
        </span>

        <div
          className={`
          flex flex-row flex-nowrap desktop:gap-[35px] laptop:gap-[35px] tablet:gap-[25px] mobile:gap-[15px] old:gap-[15px]
          desktop:pr-[100px] laptop:pr-[100px] tablet:pr-[80px] mobile:pr-[15px] old:pr-[15px]
        `}
        >
          <button
            onClick={handlePrev}
            className={'bg-transparent shadow-none w-full'}
          >
            <ArrowLeftLongBlogIcon
              className={
                'desktop:scale-150 laptop:scale-150 tablet:scale-150 mobile:scale-100 old:scale-100'
              }
            />
          </button>
          <button
            onClick={handleNext}
            className={'bg-transparent shadow-none w-full'}
          >
            <ArrowLeftLongBlogIcon
              className={
                'rotate-[180deg] desktop:scale-150 laptop:scale-150 tablet:scale-150 mobile:scale-100 old:scale-100'
              }
            />
          </button>
        </div>
      </div>

      <div ref={scrollTapeRef} className={'overflow-auto'}>
        <div
          className={
            'flex flex-row desktop:gap-[40px] overflow-auto laptop:gap-[40px] tablet:gap-[36px] mobile:gap-[15px] old:gap-[15px] w-fit'
          }
        >
          {feedbacks?.map((feedback, index) => (
            <FeedbackCard
              text={feedback}
              key={index}
              color={index % 2 ? 'bg-purpleDark' : 'bg-violet'}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeedbackCarousel;
